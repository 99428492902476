/* eslint-disable */
import {mask} from 'vue-the-mask'
import router from '../router'
export default {
  directives: {mask},
  data: () => ({
    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
  }),
  methods: {
    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
      },
    scrollTo (id, block='start') {
      var elmnt = document.getElementById(id);
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({behavior: "smooth", block: block});
      }
    },
    redimensionando(id){
        var teste_div = document.getElementById(id)
        var eleStyle = window.getComputedStyle(teste_div);
        var height = 760

        var hg = parseInt(eleStyle.width.replace('px', ''))-430
        if (parseInt(eleStyle.width.replace('px', ''))<=1100) {
          height = hg > 300 ? hg : 300
        } else {
          height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
        }
        return height
    },
    IrParaUrl(path, target='_blank'){
      if (this.validarCampo(path)) {
        var a = document.createElement('a')
        a.href = path
        a.target = target
        a.click()
      }

    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    firstWord(str) {
      var array = []
      if (this.validarCampo(str)) {
        array = str.split(" ")
        return array[0]
      } else {
        return str
      }
    },
    irPara(path, block='start') {

      var url_atual = this.retira_acentos(decodeURIComponent(window.location.pathname))

      var cortar = url_atual.replace('/', '');

      if (this.validarCampo(cortar)) {
        router.push({path:'/'}).catch(err => {
        })
        var _this = this
        var inter = setInterval(function() {
          _this.scrollTo(path, block)
          clearInterval(inter)
        },100)

      } else {
        this.scrollTo(path, block)
      }
    },
    irParaRota(path,id) {
      router.push({path:path}).catch(err => {
      })
    },

  },
}
