/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../src/assets/css/main.scss'
import 'aos/dist/aos.css'

import store from './store/index'
import * as VueGoogleMaps from 'vue2-google-maps'

import Parallax from '../src/components/Parallax.vue'
import {sync} from 'vuex-router-sync'
import Divisor from './components/divisor.vue'
import ModalCadastro from './components/ModalCadastro.vue'
import titulo from './components/titulo'
import { VueTypedJs } from 'vue-typed-js'
import ImgCardFlip from './components/ImgCardFlip'

// Vue.use(VueTypedJs)
Vue.component('img-card-flip', ImgCardFlip)

Vue.component('vue-typed-js', VueTypedJs)
Vue.component('pro-titulo', titulo)
Vue.component('pro-divisor', Divisor)
Vue.component('parallax', Parallax)
Vue.component('pro-modal', ModalCadastro)



Vue.use(Vuetify, {
  theme: {
    secondary: '#0a593b',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
})

Vue.config.productionTip = false
// A key precisará ser alterada depois
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'
  }
})


sync(store, router)

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
}

new Vue({
  router,
  store: store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app')
