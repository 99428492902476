/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './geral'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    geral,

  }
})
