<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <div id="inspire" class="scrolling baloo_paaji_regular" style="background: red">
    <v-card class="scroll-y" style="background: #EFEFEF!important;" flat>
      <!--<div style="background: red; height: 80px" class="desktop">-->
      <div style="background: transparent; height: 40px" class="desktop">
        <v-system-bar dark class="gradiente_vermelho"
                      style=" transition: all .5s ease-in-out; padding-left: 10%; padding-right: 1%;height: 40px;z-index: 1">
          <!--<v-btn name="regiao" text   @click="IrParaUrl(gmapa,'_black')"> <v-icon small>mdi-map-marker</v-icon> Caratinga</v-btn>-->
          <v-spacer></v-spacer>
          <div >
            <v-btn name="telefone" class="telefone1" text @click="IrParaUrl('tel:+553333226363', '_self')" >
              <v-icon>mdi-phone</v-icon>
              <h3>(33)3322-6363</h3>
            </v-btn>
          </div>
          <v-divider vertical style="border-color: rgba(255,255,255,.5) !important;"></v-divider>
          <div :name="icon.ico"  v-for="(icon, index) in icons" :key="index" class="mx-2" @click="IrParaUrl(icon.link)">
            <v-icon small>
              {{ icon.ico }}
            </v-icon>
          </div>
        </v-system-bar>
      </div>
      <!--DESKTOP-->

      <v-app-bar absolute :dark="true"  elevate-on-scroll :class="dark ? 'gradiente_trans animarUpBar' : 'gradiente_azul animarDownBar'"
                 scroll-target="#scrolling-techniques-7" class="appScrolling desktop " id="navapp"
                 :style="'top:' + (dark?  '40px' : '0px') + ';'">

        <v-app-bar-nav-icon class="mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-tabs id="tab_home" v-model="tab" :hide-slider="tab==='/'" right :color="dark ? '#e4e4e4' : 'white'"
                style="padding-left: 5%; padding-right: 5%;" active-class="ativo">
          <v-tabs-slider color="amber"></v-tabs-slider>

          <div @click="irPara('banner')" style="width: 200px; ">
            <v-img :src="logo" contain style="width: 100%; height: 60px"></v-img>
          </div>
          <v-spacer class="telefone1"></v-spacer>
          <v-spacer></v-spacer>
          <v-tab @click="irPara('como_funciona','center')" style="font-weight: bold; ">Como Funciona</v-tab>
          <v-tab @click="irPara('recursos','start')" style="font-weight: bold; ">Recursos</v-tab>
          <v-tab @click="irPara('valor','start')" style="font-weight: bold">Valor</v-tab>
          <v-tab @click="irPara('duvidas','start')" style="font-weight: bold">Duvidas</v-tab>
          <v-tab @click="irPara('parcerias','start')" style="font-weight: bold">Parcerias</v-tab>
        </v-tabs>
      </v-app-bar>

      <!--MOBILE-->
      <v-app-bar absolute color="white"  dark style="position: fixed"
                 scroll-target="#scrolling-techniques-7" class="gradiente_azul mobile">
        <!--gradiente_trans-->
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="z-index: 1"></v-app-bar-nav-icon>

        <div style="width: 100%;z-index: 0; margin-left: 0px">
         <v-img :src="logo" contain style="width: 100%; height: 40px; "></v-img>
       </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" fixed temporary class="gradiente_azul_verde" style="height: 100%">
        <v-list dark nav dense>
          <br>
          <v-list-item>
            <v-list-item-title>
              Menu
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="irPara('como_funciona','center')">
            <v-list-item-title>Como Funciona</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irPara('recursos','start')">
            <v-list-item-title>Recursos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irPara('valor','start')">
            <v-list-item-title>Valor</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irPara('duvidas','start')">
            <v-list-item-title>Dúvidas</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irPara('parcerias','start')">
            <v-list-item-title>Parcerias</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <br>
          <v-list-item>
            <v-list-item-title>
              <!--Redes Sociais-->
             <v-container fluid>
               <v-row justify="center">
                 <!--<v-col cols="3" v-for="(img,index) in icones.getIcones()" :key="'icones'+index">-->
                   <!--<v-btn icon @click="IrParaUrl(img.link)">-->
                     <!--<v-img :src="img.src" style="height: 30px; width: 100%" contain></v-img>-->
                   <!--</v-btn>-->
                 <!--</v-col>-->
                 <v-col cols="12">
                     <v-btn name="telefone" block outlined @click="IrParaUrl('tel:+553333226363', '_self')">
                       <v-icon>mdi-phone</v-icon>
                       <h3>(33)3322-6363</h3>
                     </v-btn>
                 </v-col>
                 <v-col cols="12">
                     <v-btn name="whatsapp" block outlined @click="IrParaUrl('https://api.whatsapp.com/send?phone=553333226363')">
                       <v-icon>mdi-whatsapp</v-icon>
                       <h3>(33)3322-6363</h3>
                     </v-btn>

                 </v-col>
               </v-row>
             </v-container>
            </v-list-item-title>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>

      <v-sheet style="background: transparent!important;" id="scrolling-techniques-7">
        <div id="scroll-target" v-scroll:#scrolling-techniques-7="onScroll"
             style="background: transparent!important; ">
          <router-view/>
        </div>
      </v-sheet>
    </v-card>

    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" width="50%">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <v-container style="text-align: left">
              <ul :key="index" v-for="(message, index) in objMsgError.details">
                <li> {{ message.mensagem }}</li>
              </ul>
            </v-container>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()" name="btnErro">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popup que vai exibir mensagens de sucesso -->
    <v-snackbar v-model="showSnack" top color="success" :timeout="2000">
      {{ msgSuccess }}
      <template v-slot:actions>
        <v-icon @click="toggleSnackSuccess">mdi-close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script async>
/* eslint-disable */

  import {mapActions, mapState} from 'vuex'
  import router from './router'
  import miscMixin from './helpers/misc'
  import logo from './assets/img/logo_w.svg'
  import icones from './assets/js/img_icones'

  export default {
    mixins: [miscMixin],
    name: 'Dash',
    data: () => ({
      logo,
      icones,
      gmapa: 'https://www.google.com.br/maps/place/Prodata+Fibra+%C3%93ptica/@-19.7932354,-42.1386247,17z/data=!4m5!3m4!1s0xba90dd38546175:0x2e97009ce3630721!8m2!3d-19.7926398!4d-42.138673?hl=pt-BR',
      tab: 0,
      fab: false,
      drawer: false,
      nome: 'Login',
      dark: true,
      icons: [
        // {
        //   ico: 'mdi-email',
        //   link: '/'
        // },
        // {
        //   ico: 'mdi-facebook',
        //   link: 'https://www.facebook.com/'
        // },
        // {
        //   ico: 'mdi-instagram',
        //   link: 'https://www.instagram.com/'
        // },
        {
          ico: 'mdi-whatsapp',
          link: 'https://api.whatsapp.com/send?phone=553333226363'
        },
      ],
    }),

    methods: {
      ...mapActions('geral', ['toggleDialogError', 'toggleSnackSuccess']),
      onScroll(e) {
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > 20
      },
    },
    components: {
      Rodape: () => import('./components/Rodape.vue')
    },
    created(){
      this.scrollTo('app')
    },
    mounted() {



      if (sessionStorage.getItem('cliente_nome') !== null) {
        this.nome = this.firstWord(sessionStorage.getItem('cliente_nome'))
      }
      // Permite usar o AOS com vuetify
      var inspire = document.getElementById('inspire')
      var _this = this
      // var elmnt = document.getElementById('navapp');

      window.onscroll = function() {
        'use strict'
        if (document.documentElement.scrollTop >= 50) {
          _this.dark = false
        } else {
          _this.dark = true
        }
      }
    },
    computed: {
      ...mapState('geral', {
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
      showSnack: {
        get() {
          return this.snackSuccess
        },
        set() {
          this.toggleSnackSuccess()
        }
      }
    },
  }
</script>
<style scoped lang="scss">

  .appScrolling {
    z-index: 100;
    position: fixed !important;
  }
  .scrolling {
    #navapp {

    }
  }
</style>
